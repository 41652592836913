@import 'scss/variables.scss';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .no-spinner {
        /* Chrome, Safari, Edge */
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('../fonts/dmSerifDisplay/DMSerifDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('../fonts/dmSerifDisplay/DMSerifDisplay-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    font-weight: 400; /* Normal weight */
    font-style: normal;
    src: url('../fonts/raleway/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 400; /* Italic weight */
    font-style: italic;
    src: url('../fonts/raleway/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
}

@layer base {
    input {
        @apply font-raleway bg-transparent border-b border-white text-secondary placeholder-secondary pb-[15px] focus:outline-none placeholder:font-raleway placeholder:uppercase placeholder:text-[18px] placeholder:tracking-[1.8px] placeholder:leading-[21] placeholder:mb-3 focus:placeholder-transparent md:text-[16px] md:placeholder:text-[16px] sm:text-[14px] text-[18px] sm:placeholder:text-[14px] rounded-none;
    }

    select {
        @apply bg-transparent border-b border-white text-secondary pb-5 focus:outline-none font-raleway uppercase text-[18px] tracking-[1.8px] w-[177px] md:text-[16px] md:w-[150px] sm:text-[14px] sm:w-[130px];
    }

    option {
        @apply bg-primary text-secondary
    }

    textarea {
        @apply bg-transparent border-b border-white text-secondary placeholder-secondary pb-[5px] focus:outline-none placeholder:font-raleway placeholder:uppercase placeholder:text-[18px] placeholder:tracking-[1.8px] placeholder:mb-3 focus:placeholder-transparent resize-none md:text-[16px] md:placeholder:text-[16px] sm:text-[14px] sm:placeholder:text-[14px] rounded-none font-raleway;
    }

    p {
        @apply font-raleway text-[16px] lg:text-[18px] tracking-[0] text-lightGray md:text-[16px] sm:text-[14px];
    }

    a {
        @apply md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]
    }
}

body {
    @apply bg-primary;
}

header {
    @apply font-raleway font-normal text-[20px] tracking-[2px] uppercase text-secondary sm:text-[18px] sm:tracking-[1.5px];
}

.title {
    @apply font-serifDisplay text-secondary text-[52px] font-normal tracking-normal leading-[60px] md:text-[60px] sm:text-[40px] lg:text-[80px];
}

.sub-title {
    @apply font-raleway text-secondary text-[30px] font-light tracking-[7px] leading-[47px] md:text-[30px] sm:text-[24px] lg:text-[40px];
}

.btn-form {
    @apply font-raleway text-lightGray uppercase text-[16px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px] tracking-[1.8px] text-left focus:outline-none half-border relative sm:text-[16px] sm:tracking-[1.6px] pb-[18.5px] md:after:w-[15%];
}

.sidebar-link {
    @apply font-raleway font-normal text-[18px] uppercase tracking-[2.7px]
}

.h1-primary {
    @apply font-serifDisplay text-lightGray text-[40px] lg:text-[60px] tracking-[0] leading-[50px] lg:leading-[82px] md:text-[50px] md:leading-[70px] sm:text-[40px] sm:leading-[60px];
}

.h1-secondary {
    @apply font-raleway text-secondary font-light uppercase text-[20px] lg:text-[30px] tracking-[5.25px] md:text-[24px] md:tracking-[4px] sm:text-[20px] sm:tracking-[3px];
}

.h2-primary {
    @apply font-serif text-lightGray text-[55px] tracking-[0] md:text-[45px] sm:text-[35px];
}

.h2-secondary {
    @apply font-raleway font-light text-lightGray uppercase text-[18px] lg:text-[20px] tracking-[3px] sm:text-[18px] sm:tracking-[2px];
}

.h3 {
    @apply font-serifDisplay text-lightGray text-[35px] lg:text-[45px] tracking-[0] md:text-[35px] sm:text-[28px];
}

.h4-primary {
    @apply font-serifDisplay font-normal text-[35px] tracking-[0] text-lightGray sm:text-[28px];
}

.h4-secondary {
    @apply font-raleway font-bold text-[18px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px] tracking-[1.8px] text-secondary uppercase sm:text-[16px] sm:tracking-[1.6px];
}

.h6-primary {
    @apply font-raleway font-normal text-[16px] tracking-[1.6px] text-lightGray uppercase
}

.h6-secondary {
    @apply font-raleway font-light md:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] tracking-[1.6px] text-secondary uppercase sm:text-[14px] sm:tracking-[1.4px];
}

.box {
    @apply px-[40px] sm:px-2 md:px-[50px] lg:px-[98px] sm:py-[30px] md:py-[60px] lg:py-[110px];
}

.sort-select {
    @apply border py-[14px] px-[29px] w-auto leading-none sm:py-[10px] sm:px-[20px];
}

ul {
    @apply list-disc list-inside
}

ol {
    @apply list-decimal list-inside
}

.half-border::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid white;
    transition: all 0.3s ease;
}

.half-border:hover::after {
    left: 0;
    width: 100%;
}

.PhoneInputCountry {
    color: #ffffff;
}
